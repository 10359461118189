import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import {
    SkillsCertificationModelList,
    SkillsCertificationUpdatePayload,
    SkillsMemberCertificationModelList,
} from 'generated-types/skills/certification';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export function useMemberCertifications({
    enabled,
    member_id,
}: {
    enabled: boolean;
    member_id?: number;
}) {
    const { data, isLoading, isSuccess, isFetched, refetch } =
        useQuery<SkillsMemberCertificationModelList>(
            ['skills_certifications_member', member_id],
            () =>
                lambdaApi
                    .get(`${skillsDBPrefix}/members/${member_id}/certifications`)
                    .then(res => res.data),
            {
                retry: false,
                enabled: enabled && !!member_id,
            },
        );

    const updateCertifications = useMutation<
        SkillsCertificationModelList,
        AxiosError,
        SkillsCertificationUpdatePayload
    >(payload => {
        return lambdaApi.put(`${skillsDBPrefix}/certifications/me`, payload).then(res => res.data);
    });

    if (data) {
        data.sort((a, b) => a.name.localeCompare(b.name));
    }

    return {
        memberCertifications: data ?? [],
        updateCertifications,
        isLoading,
        isSuccess,
        isFetched,
        refetch,
    };
}
