import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import {
    SkillsMemberSkillModelList,
    SkillsMemberSkillUpdatePayloadList,
} from 'generated-types/skills/skill';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export function useMemberSkills({ enabled, member_id }: { enabled: boolean; member_id?: number }) {
    const { data, isLoading, refetch } = useQuery<SkillsMemberSkillModelList>(
        ['skills_member_skills', member_id],
        () =>
            lambdaApi
                .get(`${skillsDBPrefix}/members/${member_id}/skills`, {})
                .then(res => res.data),
        {
            retry: false,
            enabled: enabled && !!member_id,
        },
    );

    const updateMySkills = useMutation<
        SkillsMemberSkillModelList,
        AxiosError,
        SkillsMemberSkillUpdatePayloadList
    >(payload => {
        return lambdaApi.put(`${skillsDBPrefix}/skills/me`, payload).then(res => res.data);
    });

    let result;

    if (data) {
        result = data.sort((a, b) => a.name.localeCompare(b.name));
    }

    return {
        isLoading,
        memberSkills: result ?? [],
        updateMySkills,
        refetch,
    };
}
