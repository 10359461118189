import { PDFDownloadLink } from '@react-pdf/renderer';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import PageIcon from '@rsuite/icons/Page';
import { SkillsMemberModel } from 'generated-types/skills/member';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Button, FlexboxGrid, IconButton, Loader, Panel } from 'rsuite';

const HeaderPanelPreview = ({
    document,
    member,
    navigate,
    selectedMemberId,
}: {
    document?: JSX.Element;
    member?: SkillsMemberModel;
    navigate: NavigateFunction;
    selectedMemberId?: number;
}) => (
    <>
        {document && member && (
            <PDFDownloadLink
                document={document}
                fileName={`CV ${member.firstname} ${member.lastname}`}
            >
                {({ loading }) =>
                    loading ? (
                        <Loader content="Creating link..." />
                    ) : (
                        <>
                            <IconButton
                                appearance="primary"
                                color="green"
                                icon={<FileDownloadIcon />}
                            >
                                Download PDF
                            </IconButton>{' '}
                        </>
                    )
                }
            </PDFDownloadLink>
        )}
        <Button
            onClick={() => {
                navigate(selectedMemberId ? `/resume/${selectedMemberId}` : '/resume');
            }}
        >
            Close
        </Button>
    </>
);

export const HeaderPanel = ({
    previewMode,
    document,
    member,
    selectedMemberId,
}: {
    previewMode: boolean;
    document?: JSX.Element;
    member?: SkillsMemberModel;
    selectedMemberId?: number;
}) => {
    const navigate = useNavigate();
    return (
        <Panel>
            <FlexboxGrid justify="space-between" style={{ alignItems: 'center' }}>
                <FlexboxGrid.Item>
                    {selectedMemberId ? <h2>Resume</h2> : <h2>My Resume</h2>}
                </FlexboxGrid.Item>
                <div>
                    {previewMode ? (
                        <HeaderPanelPreview
                            document={document}
                            member={member}
                            navigate={navigate}
                            selectedMemberId={selectedMemberId}
                        />
                    ) : (
                        <IconButton
                            icon={<PageIcon />}
                            onClick={() => {
                                navigate(
                                    selectedMemberId
                                        ? `/resume/${selectedMemberId}/preview`
                                        : '/resume/preview',
                                );
                            }}
                        >
                            Show as PDF
                        </IconButton>
                    )}
                </div>
            </FlexboxGrid>
        </Panel>
    );
};
