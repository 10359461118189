import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import {
    SkillsEducationBaseModel,
    SkillsEducationModelList,
} from 'generated-types/skills/education';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export function useEducations({ enabled, member_id }: { enabled: boolean; member_id?: number }) {
    const { data, isLoading, refetch } = useQuery<SkillsEducationModelList>(
        ['skills_educations', member_id],
        () =>
            lambdaApi
                .get(`${skillsDBPrefix}/members/${member_id}/educations`, {})
                .then(res => res.data),
        { retry: false, enabled: enabled && !!member_id },
    );

    const addEducation = useMutation<
        SkillsEducationModelList,
        AxiosError,
        SkillsEducationBaseModel
    >(payload => {
        return lambdaApi.post(`${skillsDBPrefix}/educations`, payload).then(res => res.data);
    });

    if (data) {
        data.sort((a, b) => {
            if (a.end_date && b.end_date) {
                return new Date(b.end_date).getTime() - new Date(a.end_date).getTime();
            } else if (a.end_date) {
                return 1;
            } else if (b.end_date) {
                return -1;
            }
            return 0;
        });
    }

    const updateEducation = useMutation<
        SkillsEducationModelList,
        AxiosError,
        { id: number; payload: SkillsEducationBaseModel }
    >(params => {
        const { id, payload } = params;
        return lambdaApi.put(`${skillsDBPrefix}/educations/${id}`, payload).then(res => res.data);
    });

    const deleteEducation = useMutation<'', AxiosError, number>(id => {
        return lambdaApi.delete(`${skillsDBPrefix}/educations/${id}`).then(res => res.data);
    });

    return {
        isLoading,
        educations: data ?? [],
        addEducation,
        updateEducation,
        deleteEducation,
        refetch,
    };
}
