import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import { SkillsCompanyModelList } from 'generated-types/skills/company';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export type UseCompaniesType = {
    label: string;
    value: number;
    group: string;
    created: boolean;
};

export function useCompanies({ enabled }: { enabled: boolean }) {
    const { data, isLoading, refetch } = useQuery<SkillsCompanyModelList>(
        ['skills_companies'],
        () => lambdaApi.get(`${skillsDBPrefix}/companies`).then(res => res.data),
        {
            retry: false,
            enabled,
        },
    );

    let result: UseCompaniesType[] = [];

    if (data) {
        result = data.map(company => ({
            label: company.name,
            value: company.id,
            group: company.circles_customer_id ? 'Circles Customers' : 'External Companies',
            created: false,
        }));
    }

    const syncCompanies = useMutation<SkillsCompanyModelList, AxiosError, unknown>(() => {
        return lambdaApi.post(`${skillsDBPrefix}/companies/sync`).then(res => res.data);
    });

    return {
        isLoading,
        companies: result,
        syncCompanies,
        refetch,
    };
}
