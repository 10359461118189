import { zodResolver } from '@hookform/resolvers/zod';
import PlusIcon from '@rsuite/icons/Plus';
import { FieldInputLength } from 'components/FieldInputLength';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { SkillsMemberModel } from 'generated-types/skills/member';
import { useSkillsMember } from 'hooks/skills/useSkillsMember';
import { useProfile } from 'hooks/useProfile';
import { useState } from 'react';
import { Control, Controller, FieldErrorsImpl, SubmitHandler, useForm } from 'react-hook-form';
import { Button, ButtonGroup, FlexboxGrid, IconButton, Input, Panel } from 'rsuite';
import {
    aboutMaxLength,
    EditProfileForm,
    EditProfileFormType,
    headlineMaxLength,
} from 'schema/skills/profile';

const ProfileEditForm = ({
    member,
    control,
    watchFields,
    errors,
}: {
    member: SkillsMemberModel;
    control: Control<EditProfileFormType>;
    watchFields: EditProfileFormType;
    errors: Partial<FieldErrorsImpl<EditProfileFormType>>;
}) => (
    <>
        <p>Headline</p>
        <Controller
            name="headline"
            control={control}
            defaultValue={member.headline ?? ''}
            render={({ field }) => <Input size="lg" {...field} />}
        />
        <FieldInputLength
            currentLength={
                watchFields.headline ? watchFields.headline.length : member.headline?.length
            }
            maxLength={headlineMaxLength}
        />
        {errors.headline?.message}
        <p>About</p>
        <Controller
            name="about"
            control={control}
            defaultValue={member?.about ?? ''}
            render={({ field }) => <Input as={'textarea'} rows={6} {...field} />}
        />
        <FieldInputLength
            currentLength={watchFields.about ? watchFields.about.length : member.about?.length}
            maxLength={aboutMaxLength}
        />
        {errors.about?.message}
    </>
);

const ProfileInfo = ({
    member,
    editAllowed,
    setEditMode,
}: {
    member: SkillsMemberModel;
    editAllowed: boolean;
    setEditMode: (value: boolean) => void;
}) =>
    editAllowed && !member.headline && !member.about ? (
        <IconButton
            size="sm"
            icon={<PlusIcon />}
            appearance="primary"
            color="green"
            onClick={() => setEditMode(true)}
        >
            Add profile information
        </IconButton>
    ) : (
        <>
            <h4>{member.headline}</h4>
            <p
                style={{
                    paddingTop: 10,
                    textAlign: 'justify',
                    whiteSpace: 'pre-wrap',
                }}
            >
                {member.about}
            </p>
        </>
    );

const ProfileModeButtons = ({
    member,
    editAllowed,
    editMode,
    isLoading,
    onClose,
    setEditMode,
}: {
    member: SkillsMemberModel;
    editAllowed: boolean;
    editMode: boolean;
    isLoading: boolean;
    onClose: () => void;
    setEditMode: (value: boolean) => void;
}) =>
    editAllowed ? (
        <FlexboxGrid.Item style={{ textAlign: 'right' }} colspan={3}>
            {editMode ? (
                <ButtonGroup>
                    <Button appearance="primary" color="green" type="submit" loading={isLoading}>
                        Save
                    </Button>
                    <Button onClick={() => onClose()}>Close</Button>
                </ButtonGroup>
            ) : (
                (member.headline || member.about) && (
                    <Button onClick={() => setEditMode(true)}>Edit</Button>
                )
            )}
        </FlexboxGrid.Item>
    ) : null;

export const Profile = ({
    member,
    editAllowed,
}: {
    member: SkillsMemberModel;
    editAllowed: boolean;
}) => {
    const { user } = useProfile();
    const { updateSkillsMember, refetch: refetchSkillsMember } = useSkillsMember({
        enabled: false,
        member_id: user?.id,
    });
    const [editMode, setEditMode] = useState(false);

    const {
        formState: { errors },
        watch,
        control,
        handleSubmit,
        resetField,
    } = useForm<EditProfileFormType>({
        resolver: zodResolver(EditProfileForm),
    });
    const watchFields = watch();

    const onSave: SubmitHandler<EditProfileFormType> = data => {
        const { about, headline } = data;

        updateSkillsMember.mutate(
            {
                about: about ? about : undefined,
                headline: headline ? headline : undefined,
            },
            {
                onSuccess: () => {
                    showNotification({ header: 'Profile updated successfully' });
                    setEditMode(false);
                    refetchSkillsMember();
                },
                onError: error => {
                    showErrorNotification(error);
                },
            },
        );
    };

    const onClose = () => {
        setEditMode(false);
        resetField('about', { defaultValue: member.about });
        resetField('headline', { defaultValue: member.headline });
    };

    return (
        <Panel bordered style={{ marginBottom: 20 }}>
            <form onSubmit={handleSubmit(onSave)}>
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item colspan={2} style={{ textAlign: 'center' }}>
                        <img
                            src={member.image_url}
                            style={{ maxWidth: 120, width: '100%', borderRadius: '50%' }}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={editAllowed ? 19 : 22} style={{ paddingLeft: 20 }}>
                        <h1>
                            {member.firstname} {member.lastname}
                        </h1>
                        {editMode ? (
                            <ProfileEditForm
                                member={member}
                                control={control}
                                watchFields={watchFields}
                                errors={errors}
                            />
                        ) : (
                            <ProfileInfo
                                member={member}
                                editAllowed={editAllowed}
                                setEditMode={setEditMode}
                            />
                        )}
                    </FlexboxGrid.Item>
                    <ProfileModeButtons
                        member={member}
                        editAllowed={editAllowed}
                        editMode={editMode}
                        isLoading={updateSkillsMember.isLoading}
                        onClose={onClose}
                        setEditMode={setEditMode}
                    />
                </FlexboxGrid>
            </form>
        </Panel>
    );
};
