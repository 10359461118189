// @ts-nocheck - TODO: still too many type-errors to fix completely
import jwtDecode from 'jwt-decode';

import config from '../config.json';
import { useLocalStorage } from '../hooks/misc/useLocalStorage';

const COGNITO_CLIENT_ID = config.MyCirclesUserPoolClientId;
const COGNITO_CLIENT_SECRET = false; // Not configured.
const COGNITO_DOMAIN = config.InfraCirclesStaffUserPoolDomain;

type GrantType = 'authorization_code' | 'refresh_token';

type CognitoState = {
    accessToken: null | string;
    idToken: null | string;
    idTokenExpiresAt: number | null;
    idTokenPayload: null | string;
    refresh_token: null | string;
};

type TokenBody = {
    client_id: string;
    redirect_uri: string;
    client_secret?: string;
    code?: string;
    grant_type?: GrantType;
    refresh_token?: string;
};

const DEFAULT_STATE: CognitoState = {
    accessToken: null,
    idToken: null,
    idTokenExpiresAt: null,
    idTokenPayload: null,
    refresh_token: null,
};
const redirect_uri = window.location.origin;

let fetchTokensPromise: Promise<void>;
let state = { ...DEFAULT_STATE };

export const launchSigninFlow = () =>
    window.location.replace(
        `https://${COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirect_uri}&response_type=CODE&client_id=${COGNITO_CLIENT_ID}&scope=email+openid+profile`,
    );

export const resetCognitoSession = () => {
    state = { ...DEFAULT_STATE };
    localStorage.removeItem('CognitoSession');
};

const readCodeFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search.substring(1));
    const code = searchParams.get('code');
    if (code) {
        resetCognitoSession();
        window.history.replaceState(null, document.title, window.location.origin);
        console.log('Read and cleared the code from the url.');
    }
    const error_description = searchParams.get('error_description');
    if (error_description) {
        console.error(
            'Cognito error in the URL search params: ',
            error_description,
            searchParams.get('error'),
        );
    }

    return code;
};

const saveState = () => localStorage.setItem('CognitoSession', JSON.stringify(state));

const fetchCredentials = async (code: string) => {
    const body: TokenBody = {
        client_id: COGNITO_CLIENT_ID,
        redirect_uri,
    };
    if (COGNITO_CLIENT_SECRET) {
        body.client_secret = COGNITO_CLIENT_SECRET;
    }
    if (code) {
        console.log('Fetching tokens with code..');
        body.code = code;
        body.grant_type = 'authorization_code';
    } else if (state.refresh_token) {
        console.log('Refreshing tokens..');
        body.grant_type = 'refresh_token';
        body.refresh_token = state.refresh_token;
    } else {
        throw new Error('COGNITO: Token requested without session.');
    }

    try {
        const resp = await (
            await fetch(`https://${COGNITO_DOMAIN}/oauth2/token`, {
                body: new URLSearchParams(body),
                method: 'POST',
            })
        ).json();
        state.accessToken = resp.access_token;
        state.idToken = resp.id_token;
        state.idTokenPayload = jwtDecode(state.idToken);
        state.idTokenExpiresAt = state.idTokenPayload.exp * 1000; // IdToken expiration time in ms.
        state.refresh_token = resp.refresh_token;

        console.log('Got new tokens.');

        saveState();
    } catch (e) {
        resetCognitoSession();
        console.error('Error fetching Cognito tokens.');
    }
};

export const getIdToken = async () => {
    await fetchTokensPromise;
    if (state.idTokenExpiresAt < new Date().getTime()) {
        fetchTokensPromise = fetchCredentials();
        await fetchTokensPromise;
    }
    if (!state.idToken) {
        throw new Error('No token even though we should have one.');
    }
    return state.idToken;
};

export const gotCognitoSession = () => {
    const code = readCodeFromUrl();

    if (code) {
        fetchTokensPromise = fetchCredentials(code);
    } else {
        const [cognitoSession] = useLocalStorage<CognitoState>('CognitoSession', {
            ...DEFAULT_STATE,
        });
        state = cognitoSession;
    }

    return !!code || !!state.idToken;
};
