import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import {
    SkillsCertificationBaseModel,
    SkillsCertificationModel,
} from 'generated-types/skills/certification';
import { Button, Checkbox, FlexboxGrid, Input, Panel } from 'rsuite';

import CertificateBlank from '../../../assets/icons/certificate_blank.png';

const CertificationBagde = ({ certification }: { certification: SkillsCertificationModel }) =>
    certification.badge_image_url ? (
        <img height={150} src={certification.badge_image_url} />
    ) : (
        <img height={150} src={CertificateBlank} />
    );

export const CertificationSelect = ({
    certification,
    selectedCertifications,
    changeSelection,
    deleteCertification,
    userId,
}: {
    certification: SkillsCertificationModel;
    selectedCertifications: SkillsCertificationBaseModel[];
    changeSelection: (id: number, selected: boolean, validation?: string) => void;
    deleteCertification: (id: number) => void;
    userId?: number;
}) => {
    const certificationSelected = selectedCertifications.some(
        selectedC => certification.id === selectedC.id,
    );
    const certificationValidation = selectedCertifications.find(
        selectedC => certification.id === selectedC.id,
    )?.validation;
    return (
        <FlexboxGrid.Item key={certification.id}>
            <Panel
                shaded
                key={certification.id}
                style={{
                    width: 300,
                    height: 320,
                    margin: 10,
                    opacity: certificationSelected ? 1 : 0.5,
                    position: 'relative',
                }}
                header={
                    <FlexboxGrid
                        justify="center"
                        style={{
                            width: '100%',
                            overflow: 'hidden',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            padding: 10,
                        }}
                    >
                        <FlexboxGrid.Item colspan={3}>
                            <Checkbox
                                checked={certificationSelected}
                                onChange={(value, checked) =>
                                    changeSelection(certification.id, checked)
                                }
                            />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            colspan={16}
                            style={{ paddingTop: 5 }}
                            onClick={() =>
                                changeSelection(certification.id, !certificationSelected)
                            }
                        >
                            {certification.issuer && <small>{certification.issuer}</small>}
                            <h6>{certification.name}</h6>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={5}>
                            {userId === certification.added_by && (
                                <ConfirmationDialog
                                    header="Delete Certification"
                                    message="Are you sure you want to delete this certification?"
                                    handleOK={() => {
                                        deleteCertification(certification.id);
                                    }}
                                >
                                    <Button appearance="primary" color="red">
                                        Delete
                                    </Button>
                                </ConfirmationDialog>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                }
            >
                <div
                    style={{
                        textAlign: 'center',
                        position: 'absolute',
                        bottom: 10,
                        right: 10,
                        left: 10,
                    }}
                >
                    <Button
                        appearance="link"
                        style={{ color: 'inherit' }}
                        onClick={() => changeSelection(certification.id, !certificationSelected)}
                    >
                        <CertificationBagde certification={certification} />
                    </Button>
                    <div
                        style={{
                            width: '100%',
                            height: 20,
                            marginTop: 10,
                            fontFamily: 'Monospace',
                        }}
                    >
                        {certificationSelected && (
                            <Input
                                placeholder="Public validation URL or key"
                                onChange={value => changeSelection(certification.id, true, value)}
                                defaultValue={certificationValidation}
                                size="xs"
                            />
                        )}
                    </div>
                </div>
            </Panel>
        </FlexboxGrid.Item>
    );
};
