import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { CircleModel } from '../generated-types/circle';
import { MembershipList } from '../generated-types/membership';

type Props = {
    userId?: number;
    getCircleMemberships?: boolean;
    circle_id?: number;
};

export function useMemberships(props?: Props) {
    const myMemberships = useQuery<MembershipList>(['myMemberships'], () =>
        lambdaApi.get('/me/membership', {}).then(res => res.data),
    );

    const memberships = useQuery<MembershipList>(['allMemberships'], () =>
        lambdaApi.get('/me/memberships', {}).then(res => res.data),
    );

    const circleMemberships = useQuery<{ [key: number]: number[] }>(
        ['circleMemberships'],
        () => lambdaApi.get('/me/memberships/circles', {}).then(res => res.data),
        { enabled: Boolean(props?.getCircleMemberships) },
    );

    const joinMembership = useMutation<CircleModel, AxiosError, number>(
        id => {
            return lambdaApi.post(`/me/membership/${id}`).then(res => res.data);
        },
        {
            onSuccess() {
                myMemberships.refetch();
            },
        },
    );

    const leaveMembership = useMutation<CircleModel, AxiosError, number>(
        id => {
            return lambdaApi.delete(`/me/membership/${id}`).then(res => res.data);
        },
        {
            onSuccess() {
                myMemberships.refetch();
            },
        },
    );

    const addUserToMembership = useMutation<
        CircleModel,
        AxiosError,
        { userId: number; circleId: number }
    >(
        payload => {
            const { userId, circleId } = payload;
            return lambdaApi.post(`/membership/${circleId}/${userId}`).then(res => res.data);
        },
        {
            onSuccess() {
                memberships.refetch();
            },
        },
    );

    const removeUserFromMembership = useMutation<
        CircleModel,
        AxiosError,
        { userId: number; circleId: number }
    >(
        payload => {
            const { userId, circleId } = payload;
            return lambdaApi.delete(`/membership/${circleId}/${userId}`).then(res => res.data);
        },
        {
            onSuccess() {
                memberships.refetch();
            },
        },
    );

    let filteredData: MembershipList = [];
    if (memberships.data) {
        filteredData = memberships.data;

        if (props?.userId) {
            filteredData = memberships.data.filter(m => {
                return m.member_id === props.userId;
            });
        }

        if (props?.circle_id) {
            filteredData = filteredData.filter(ms => {
                return ms.circle_id === props.circle_id && !ms.end_date;
            });
        }
    }

    return {
        myMemberships: myMemberships?.data ?? [],
        memberships: filteredData,
        circleMemberships,
        joinMembership,
        leaveMembership,
        addUserToMembership,
        removeUserFromMembership,
    };
}
