import 'rsuite/dist/rsuite.min.css';

import { setupAxios } from 'api/LambdaApi';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { CustomProvider } from 'rsuite';

import { App } from './App';
import SignInGate from './components/SignInGate';
import { envConfig } from './config';
import { useLocalStorage } from './hooks/misc/useLocalStorage';
import * as serviceWorker from './serviceWorker';
const stage = envConfig.app.stage;

if (stage === 'dev') {
    window.document.title = 'LOCAL - MyCircles';
} else if (stage === 'staging') {
    window.document.title = 'STG - MyCircles';
}

const ThemeWrapper = ({ children }: { children: JSX.Element }) => {
    const [theme] = useLocalStorage<'light' | 'dark'>('theme', 'light');
    return <CustomProvider theme={theme}>{children}</CustomProvider>;
};

setupAxios();

ReactDOM.render(
    <ThemeWrapper>
        <HashRouter>
            <SignInGate>
                <App />
            </SignInGate>
        </HashRouter>
    </ThemeWrapper>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
