import { zodResolver } from '@hookform/resolvers/zod';
import { FieldInputLength } from 'components/FieldInputLength';
import { CloseFn } from 'components/misc/ExpandableForm';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isFuture from 'date-fns/isFuture';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { SkillsCompanyRequestPayload } from 'generated-types/skills/company';
import { useCompanies, UseCompaniesType } from 'hooks/skills/useCompanies';
import { useExperiences } from 'hooks/skills/useExperiences';
import { useMemberSkills } from 'hooks/skills/useMemberSkills';
import { useSkills, UseSkillsType } from 'hooks/skills/useSkills';
import { useProfile } from 'hooks/useProfile';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Checkbox, Col, DatePicker, Input, InputPicker, Row, TagPicker } from 'rsuite';
import {
    experienceDescriptionMaxLength,
    NewExperienceForm,
    NewExperienceFormType,
    titleMaxLength,
} from 'schema/skills/experience';
import { formatDatesInterval } from 'utils/formatters';

import { ErrorParagraph } from '../log-hours/styles';

export const ExperienceAddForm = ({ onAdded }: { onAdded: CloseFn }) => {
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const [isInternalProject, setIsInternalProject] = useState<boolean>(false);
    const [selectedCompany, setSelectedCompany] = useState<UseCompaniesType>();
    const [newCompanies, setNewCompanies] = useState<UseCompaniesType[]>([]);
    const [newSkills, setNewSkills] = useState<UseSkillsType>([]);
    const { user } = useProfile();
    const { addExperience, refetch: refetchExperiences } = useExperiences({
        enabled: false,
        member_id: user?.id,
    });
    const { refetch: refetchMemberSkills } = useMemberSkills({
        enabled: false,
        member_id: user?.id,
    });
    const { skills, refetch: refetchSkills } = useSkills({ enabled: true });
    const { companies, refetch: refetchCompanies } = useCompanies({ enabled: true });
    const {
        formState: { errors },
        watch,
        control,
        handleSubmit,
        reset,
    } = useForm<NewExperienceFormType>({
        resolver: zodResolver(NewExperienceForm),
    });
    const watchFields = watch();

    const onSubmit: SubmitHandler<NewExperienceFormType> = data => {
        const { title, description, start_date, selected_skills } = data;
        const company: SkillsCompanyRequestPayload | undefined =
            selectedCompany && !isInternalProject
                ? {
                      name: selectedCompany.label,
                      existing_company_id: selectedCompany.created
                          ? undefined
                          : selectedCompany.value,
                  }
                : undefined;

        addExperience.mutate(
            {
                title,
                description,
                start_date: format(startDate ? startDate : start_date, 'yyyy-MM-dd'),
                end_date: endDate && format(endDate, 'yyyy-MM-dd'),
                skills: selected_skills
                    ? selected_skills.map(
                          s =>
                              skills.find(s2 => s2.value === s)?.label || // Endpoint expects labels
                              s, // If the skill is new, value contains the name already
                      )
                    : undefined,
                company,
                is_internal_project: isInternalProject,
            },
            {
                onSuccess: () => {
                    showNotification({ header: 'Experience added successfully.' });
                    setIsInternalProject(false);
                    reset({
                        title: undefined,
                        description: undefined,
                        // @ts-ignore
                        start_date: null,
                        end_date: null,
                        selected_skills: null,
                        selected_company: null,
                        is_internal_project: isInternalProject,
                    });
                    refetchExperiences();
                    refetchMemberSkills();
                    refetchSkills();
                    refetchCompanies();
                    setStartDate(undefined);
                    setEndDate(undefined);
                    onAdded();
                },
                onError: error => {
                    showErrorNotification(error);
                },
            },
        );
    };

    const handleRangeSelect = (dateType: 'start' | 'end', date: Date) => {
        date.setMilliseconds(0);
        date.setSeconds(0);
        date.setMinutes(0);
        date.setHours(0);
        if (dateType === 'start') {
            date.setDate(1);
            setStartDate(date);
        } else {
            date.setDate(lastDayOfMonth(date).getDate());
            setEndDate(date);
        }
    };

    useEffect(() => {
        setSelectedCompany(undefined);
    }, [isInternalProject]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xs={18}>
                    <p>Company</p>
                    <InputPicker
                        creatable
                        value={selectedCompany ? selectedCompany.value : null}
                        onSelect={(value, item) => {
                            setSelectedCompany({
                                label: String(item.label),
                                value: Number(value),
                                group: item.group,
                                created: item.created,
                            });
                        }}
                        onClean={() => setSelectedCompany(undefined)}
                        onCreate={(value, item) => {
                            const newId =
                                Math.max(
                                    ...companies.concat(newCompanies).map(company => company.value),
                                ) + 1;
                            const newCompany = {
                                label: String(item.label),
                                value: newId,
                                group: item.group,
                                created: true,
                            };
                            setNewCompanies([...newCompanies, newCompany]);
                            setSelectedCompany(newCompany);
                        }}
                        placeholder="Select or create new"
                        groupBy="group"
                        data={companies.concat(newCompanies)}
                        disabled={isInternalProject}
                        block
                        size="lg"
                    />
                </Col>
                <Col xs={6} style={{ paddingTop: 19 }}>
                    <Checkbox
                        checked={isInternalProject}
                        onChange={() => setIsInternalProject(!isInternalProject)}
                    >
                        Circles Internal Project
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <p>
                        Title<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="title"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <>
                                <Input size="lg" {...field} />
                                <FieldInputLength
                                    currentLength={watchFields.title?.length}
                                    maxLength={titleMaxLength}
                                />
                            </>
                        )}
                    />
                    <ErrorParagraph>{errors?.title?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <p>
                        Start<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="start_date"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                oneTap
                                disabledDate={date => {
                                    if (date && endDate) {
                                        return isAfter(date, endDate);
                                    } else if (date) {
                                        return isFuture(date);
                                    }
                                    return false;
                                }}
                                ranges={[]}
                                format="MMM yyyy"
                                limitEndYear={50}
                                onSelect={date => handleRangeSelect('start', date)}
                                onClean={() => setStartDate(undefined)}
                                onEmptied={() => setStartDate(undefined)}
                                {...field}
                            />
                        )}
                    />
                    <ErrorParagraph>{errors?.start_date?.message}</ErrorParagraph>
                </Col>
                <Col xs={8}>
                    <p>End</p>
                    <Controller
                        name="end_date"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                oneTap
                                disabledDate={date => {
                                    if (date && startDate) {
                                        return isBefore(date, startDate);
                                    }
                                    return false;
                                }}
                                ranges={[]}
                                format="MMM yyyy"
                                limitEndYear={50}
                                onSelect={date => handleRangeSelect('end', date)}
                                onClean={() => setEndDate(undefined)}
                                onEmptied={() => setEndDate(undefined)}
                                {...field}
                            />
                        )}
                    />
                    <ErrorParagraph>{errors?.end_date?.message}</ErrorParagraph>
                </Col>
                <Col xs={8} style={{ paddingTop: 25 }}>
                    {formatDatesInterval(startDate, endDate)}
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <p>
                        Description<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="description"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <>
                                <Input size="lg" as="textarea" rows={5} {...field} />
                                <FieldInputLength
                                    currentLength={watchFields.description?.length}
                                    maxLength={experienceDescriptionMaxLength}
                                />
                            </>
                        )}
                    />
                    <ErrorParagraph>{errors?.description?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <p>Skills</p>
                    <Controller
                        name="selected_skills"
                        control={control}
                        render={({ field }) => (
                            <TagPicker
                                creatable
                                onCreate={(value, item) => {
                                    setNewSkills([
                                        ...newSkills,
                                        {
                                            label: String(item.label),
                                            value: String(item.value),
                                        },
                                    ]);
                                }}
                                placeholder="Select or create new"
                                data={skills.concat(newSkills)}
                                style={{ width: '100%' }}
                                {...field}
                            />
                        )}
                    />
                    <ErrorParagraph>{errors?.selected_skills?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <Col xs={24} style={{ padding: 15 }}>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            type="submit"
                            appearance="primary"
                            color="green"
                            style={{ width: 300 }}
                            loading={addExperience.isLoading}
                        >
                            Add new experience
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};
