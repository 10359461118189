import { zodResolver } from '@hookform/resolvers/zod';
import { FieldInputLength } from 'components/FieldInputLength';
import { CloseFn } from 'components/misc/ExpandableForm';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import format from 'date-fns/format';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { useEducations } from 'hooks/skills/useEducations';
import { useProfile } from 'hooks/useProfile';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Col, DatePicker, Input, Row } from 'rsuite';
import {
    degreeMaxLength,
    educationDescriptionMaxLength,
    NewEducationForm,
    NewEducationFormType,
} from 'schema/skills/education';

import { ErrorParagraph } from '../log-hours/styles';

export const EducationAddForm = ({ onAdded }: { onAdded: CloseFn }) => {
    const [endDate, setEndDate] = useState<Date | undefined>();
    const { user } = useProfile();
    const { addEducation, refetch: refetchEducations } = useEducations({
        enabled: false,
        member_id: user?.id,
    });
    const {
        formState: { errors },
        watch,
        control,
        handleSubmit,
        reset,
    } = useForm<NewEducationFormType>({
        resolver: zodResolver(NewEducationForm),
    });
    const watchFields = watch();

    const onSubmit: SubmitHandler<NewEducationFormType> = data => {
        const { degree, description } = data;
        addEducation.mutate(
            {
                degree,
                description,
                end_date: endDate && format(endDate, 'yyyy-MM-dd'),
            },
            {
                onSuccess: () => {
                    showNotification({ header: 'Education added successfully' });
                    reset({
                        degree: undefined,
                        description: undefined,
                        end_date: null,
                    });
                    refetchEducations();
                    onAdded();
                },
                onError: error => {
                    showErrorNotification(error);
                },
            },
        );
    };

    const handleRangeSelect = (date: Date) => {
        date.setMilliseconds(0);
        date.setSeconds(0);
        date.setMinutes(0);
        date.setHours(0);
        date.setDate(lastDayOfMonth(date).getDate());
        setEndDate(date);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xs={24}>
                    <p>
                        Degree<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="degree"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <>
                                <Input size="lg" {...field} />
                                <FieldInputLength
                                    currentLength={watchFields.degree?.length}
                                    maxLength={degreeMaxLength}
                                />
                            </>
                        )}
                    />
                    <ErrorParagraph>{errors?.degree?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <p>Completed on</p>
                    <Controller
                        name="end_date"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                oneTap
                                ranges={[]}
                                format="MMM yyyy"
                                limitEndYear={50}
                                onSelect={date => handleRangeSelect(date)}
                                onClean={() => setEndDate(undefined)}
                                onEmptied={() => setEndDate(undefined)}
                                {...field}
                            />
                        )}
                    />
                    <ErrorParagraph>{errors?.end_date?.message}</ErrorParagraph>
                </Col>
            </Row>

            <Row>
                <Col xs={24}>
                    <p>
                        Description<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="description"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <>
                                <Input size="lg" as="textarea" rows={5} {...field} />
                                <FieldInputLength
                                    currentLength={watchFields.description?.length}
                                    maxLength={educationDescriptionMaxLength}
                                />
                            </>
                        )}
                    />
                    <ErrorParagraph>{errors?.description?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <Col xs={24} style={{ padding: 15 }}>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            type="submit"
                            appearance="primary"
                            color="green"
                            style={{ width: 300 }}
                            loading={addEducation.isLoading}
                        >
                            Add new education
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};
