import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import { ProfileUpdatePayload, SkillsMemberModel } from 'generated-types/skills/member';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export function useSkillsMember({ enabled, member_id }: { enabled: boolean; member_id?: number }) {
    const { data, isLoading, isSuccess, isFetched, refetch } = useQuery<SkillsMemberModel>(
        ['skills_member', member_id],
        () => lambdaApi.get(`${skillsDBPrefix}/members/${member_id ?? 'me'}`).then(res => res.data),
        {
            retry: false,
            enabled,
        },
    );

    const updateSkillsMember = useMutation<SkillsMemberModel, AxiosError, ProfileUpdatePayload>(
        payload => {
            return lambdaApi.put(`${skillsDBPrefix}/members/me`, payload).then(res => res.data);
        },
    );

    const syncSkillsMembers = useMutation<SkillsMemberModel, AxiosError, unknown>(() => {
        return lambdaApi.post(`${skillsDBPrefix}/members/sync`).then(res => res.data);
    });

    return {
        skillsMember: data,
        isLoading,
        isSuccess,
        isFetched,
        updateSkillsMember,
        syncSkillsMembers,
        refetch,
    };
}
