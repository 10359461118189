import { zodResolver } from '@hookform/resolvers/zod';
import { queryClient } from 'App';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { MYSQL_DATE_STRING } from 'config';
import add from 'date-fns/add';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';
import isWeekend from 'date-fns/isWeekend';
import { TimeTrackModel } from 'generated-types/timetrack';
import { useInternalProjects } from 'hooks/useInternalProjects';
import { useMyAssignments } from 'hooks/useMyAssignments';
import { useMyCustomerProjects } from 'hooks/useMyCustomerProjects';
import { useProfile } from 'hooks/useProfile';
import { TimeTrackQuery, useTimeTracks } from 'hooks/useTimeTracks';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Col, Input, Row } from 'rsuite';
import { WorkTimeTrack, WorkTimeTrackType } from 'schema/timetracks';
import { isHoliday } from 'utils/holidays';

import { LogHoursApplyParams, scrollToElement } from '..';
import { ErrorParagraph, formStyles } from '../styles';
import { ActionButtons } from './ActionButtons';
import { HoursSelector } from './HoursSelector';
import { LocationSelector } from './LocationSelector';
import { ProjectSelector } from './ProjectSelector';
import {
    HandleTimeTrackDelete,
    InternalProjectOptions,
    ProjectOptions,
    SuggestedInternalProject,
} from './Types';

type Props = {
    internalProjectIdSuggestion: number | undefined;
    existingDayLocation: TimeTrackModel['location'] | undefined;
    dayHourTotal: number;
    selectedDate: Date;
    ttQuery: TimeTrackQuery;
    selectedTimetrack: TimeTrackModel | undefined;
    applyParams: (param: LogHoursApplyParams, value: string) => void;
};

const nextDayButtonID = 'addNewMoveNextDayBtn';

export function LogWorkForm({
    selectedDate,
    ttQuery,
    selectedTimetrack: tt,
    dayHourTotal,
    existingDayLocation,
    internalProjectIdSuggestion,
    applyParams,
}: Props) {
    const {
        formState: { errors },
        control,
        setValue,
        handleSubmit,
        register,
        resetField,
        getValues,
    } = useForm<WorkTimeTrackType>({
        resolver: zodResolver(WorkTimeTrack),
    });

    const { addPersonalTimeTrack, deleteTimeTrack, updateTimeTrack } = useTimeTracks({});
    const { myCustomerProjects } = useMyCustomerProjects();
    const [projectName, setSelectedProjectName] = useState<string>('Select Project');
    const [showProjectSelection, setShowProjectSelection] = useState(false);

    const [startTime, setStartTime] = useState<Date | undefined>();
    const [endTime, setEndTime] = useState<Date | undefined>();
    const { internalProjects } = useInternalProjects({ sort: { field: 'name', order: 'asc' } });
    const { user } = useProfile();

    const projectOptions: ProjectOptions = myCustomerProjects.map(proj => {
        return {
            label: proj.name ?? 'Not found',
            value: proj.assignment_id,
            billing_rate: proj.billing_rate,
            start_date: proj.start_date,
            end_date: proj.end_date,
        };
    });

    const internalProjectOptions: InternalProjectOptions = internalProjects.map(ip => {
        return {
            label: ip.name,
            value: ip.id,
        };
    });

    const suggestedInternalProject: SuggestedInternalProject = internalProjectOptions.find(p => {
        return p.value === internalProjectIdSuggestion;
    });

    const { assignment_id, internal_project_id } = getValues();
    const suggestedInternalProjectSelected =
        suggestedInternalProject?.value === internal_project_id;
    const assignmentSelected = Boolean(assignment_id);

    const nextWorkDay = eachDayOfInterval({
        start: add(selectedDate, { days: 1 }),
        end: add(selectedDate, { weeks: 1 }),
    }).filter(date => !isWeekend(date) && !isHoliday(date))[0];

    const handleAssignmentSelection = (assignment: {
        label: string;
        value: number;
        billing_rate: number;
    }) => {
        setSelectedProjectName(assignment.label);
        setShowProjectSelection(false);
        setValue('assignment_id', assignment.value);
        setValue('billing_rate', assignment.billing_rate);
        resetField('internal_project_id');
    };

    const handleInternalProjectSelection = (
        _: unknown,
        project: { label: string; value: number },
    ) => {
        setSelectedProjectName(project.label);
        setShowProjectSelection(false);
        resetField('assignment_id');
        resetField('billing_rate');
        setValue('internal_project_id', project.value);
    };

    const onSubmit: SubmitHandler<WorkTimeTrackType> = (data, event) => {
        const { nativeEvent } = event as React.SyntheticEvent<HTMLFormElement, SubmitEvent>;
        const moveToNextDay = nextDayButtonID === nativeEvent?.submitter?.id;
        const { id, ...rest } = data;
        if (id) {
            updateTimeTrack.mutate(
                {
                    id: Number(id),
                    payload: {
                        ...rest,
                        date: format(selectedDate, MYSQL_DATE_STRING),
                        member_id: Number(rest.member_id),
                    },
                },
                {
                    onSuccess: () => {
                        applyParams('timetrack_id', '');
                        showNotification({ header: 'Timetrack updated succesfully.' });
                        queryClient.invalidateQueries([ttQuery]);
                        applyParams('date', format(selectedDate, 'yyyy-MM-dd'));
                        scrollToElement('header');
                    },
                    onError: err => {
                        showErrorNotification(err);
                    },
                },
            );
        } else {
            addPersonalTimeTrack.mutate(
                {
                    ...data,
                    date: format(selectedDate, MYSQL_DATE_STRING),
                },
                {
                    onSuccess: () => {
                        setValue('comment', '');
                        setStartTime(undefined);
                        setEndTime(undefined);

                        showNotification({ header: 'Timetrack submitted succesfully.' });
                        queryClient.invalidateQueries([ttQuery]);
                        if (moveToNextDay) {
                            applyParams('date', format(nextWorkDay, 'yyyy-MM-dd'));
                        }
                    },
                    onError: err => {
                        showErrorNotification(err);
                    },
                },
            );
        }
    };

    const handleTimeTrackDelete: HandleTimeTrackDelete = () => {
        if (!tt) return;

        deleteTimeTrack.mutate(tt.id, {
            onSuccess: () => {
                showNotification({ header: 'Timetrack deleted succesfully!' });
                queryClient.invalidateQueries([ttQuery]);
                applyParams('timetrack_id', '');
                scrollToElement('header');
            },
        });
    };

    useEffect(() => {
        if (existingDayLocation) {
            setValue('location', existingDayLocation);
        } else if (tt?.location) {
            setValue('location', tt.location);
        } else if (user?.location) {
            setValue('location', user.location);
        }
    }, [user, tt]);

    useEffect(() => {
        if (tt) {
            const assignment = projectOptions.find(a => {
                return a.value === tt?.assignment_id;
            });
            const internalProject = internalProjectOptions.find(a => {
                return a.value === tt?.internal_project_id;
            });

            if (assignment) handleAssignmentSelection(assignment);
            if (internalProject) handleInternalProjectSelection('', internalProject);
        }
    }, [tt]);

    useEffect(() => {
        function handleClick(event: MouseEvent) {
            const target = event.target as Node;
            const component = document.getElementById('projectselectionbox');
            const dropdown = document.querySelector('.rs-picker-select-menu');

            const clickInsideDropdown = dropdown && dropdown.contains(target);
            const clickInsideProjectBox = component?.contains(target);
            if (!clickInsideProjectBox && !clickInsideDropdown) {
                setShowProjectSelection(false);
            }
        }
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const userId = user?.id;
    const timetrackNotEditable = tt?.is_confirmed || tt?.is_locked;
    return (
        <form className={formStyles} onSubmit={handleSubmit(onSubmit)}>
            <input hidden {...register('id')} value={tt?.id ?? undefined} />
            {userId && <input hidden {...register('member_id')} value={userId} />}
            <input hidden {...register('assignment_id')} value={tt?.assignment_id ?? ''} />
            <input hidden {...register('billing_rate')} value={tt?.billing_rate ?? 0} />
            <Row>
                <Col xl={6}>
                    <p>Date</p>
                    <Button disabled>
                        <p>{selectedDate.toDateString()}</p>
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p>Project</p>
                    <Button
                        onClick={() => setShowProjectSelection(!showProjectSelection)}
                        disabled={Boolean(tt)}
                        appearance="ghost"
                        color="blue"
                    >
                        {projectName ?? 'Select'}
                    </Button>
                </Col>
            </Row>
            {showProjectSelection ? (
                <Row>
                    <ProjectSelector
                        control={control}
                        internalProjectOptions={internalProjectOptions}
                        projectOptions={projectOptions}
                        assignmentSelected={assignmentSelected}
                        suggestedInternalProject={suggestedInternalProject}
                        handleInternalProjectSelection={handleInternalProjectSelection}
                        suggestedInternalProjectSelected={suggestedInternalProjectSelected}
                        handleAssignmentSelection={handleAssignmentSelection}
                        tt={tt}
                        selectedDate={selectedDate}
                    />
                </Row>
            ) : null}
            <Row>
                {/* @ts-ignore */}
                <ErrorParagraph>{errors?.internal_or_assignment?.message}</ErrorParagraph>
            </Row>

            <Row>
                <p>Location</p>
                <LocationSelector
                    control={control}
                    setValue={setValue}
                    timetrackNotEditable={timetrackNotEditable}
                />
                <ErrorParagraph>{errors?.location?.message}</ErrorParagraph>
            </Row>

            <Row style={{ height: 60 }}>
                <p>Hours</p>
                <HoursSelector
                    tt={tt}
                    control={control}
                    dayHourTotal={dayHourTotal}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    setValue={setValue}
                    timetrackNotEditable={timetrackNotEditable}
                />
            </Row>
            <Row>
                <ErrorParagraph>{errors?.hours?.message}</ErrorParagraph>
            </Row>
            <Row>
                <p>Comment</p>
                <Controller
                    name="comment"
                    control={control}
                    defaultValue={tt?.comment}
                    render={({ field }) => (
                        <Input
                            as="textarea"
                            type="text"
                            rows={3}
                            readOnly={tt?.is_locked || tt?.is_confirmed}
                            {...field}
                        />
                    )}
                />
                <ErrorParagraph>{errors?.comment?.message}</ErrorParagraph>
            </Row>
            <Row>
                <ActionButtons
                    tt={tt}
                    timetrackNotEditable={timetrackNotEditable}
                    nextDayButtonID={nextDayButtonID}
                    updateTimeTrack={updateTimeTrack}
                    selectedDate={selectedDate}
                    handleTimeTrackDelete={handleTimeTrackDelete}
                    deleteTimeTrack={deleteTimeTrack}
                    addPersonalTimeTrack={addPersonalTimeTrack}
                />
            </Row>
        </form>
    );
}
