import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import {
    NewCertificationModel,
    SkillsCertificationModel,
    SkillsCertificationModelList,
} from 'generated-types/skills/certification';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export function useCertifications({ enabled }: { enabled: boolean }) {
    const { data, isLoading, refetch } = useQuery<SkillsCertificationModelList>(
        ['skills_certifications'],
        () => lambdaApi.get(`${skillsDBPrefix}/certifications`).then(res => res.data),
        {
            retry: false,
            enabled,
        },
    );

    const addCertification = useMutation<
        SkillsCertificationModel,
        AxiosError,
        NewCertificationModel
    >(
        ['skills_certifications'],
        payload =>
            lambdaApi.post(`${skillsDBPrefix}/certifications`, payload).then(res => res.data),
        {
            retry: false,
        },
    );

    const deleteCertification = useMutation<null, AxiosError, { id: number }>(
        ['skills_certifications'],
        payload =>
            lambdaApi
                .delete(`${skillsDBPrefix}/certifications/${payload.id}`)
                .then(res => res.data),
        { retry: false },
    );

    if (data) {
        data.sort((a, b) => a.name.localeCompare(b.name));
    }

    return {
        isLoading,
        addCertification,
        deleteCertification,
        certifications: data ?? [],
        refetch,
    };
}
