import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import {
    SkillsExperienceModel,
    SkillsExperienceModelList,
    SkillsExperienceRequestPayload,
} from 'generated-types/skills/experience';
import { useMutation, useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export function useExperiences({ enabled, member_id }: { enabled: boolean; member_id?: number }) {
    const { data, isLoading, refetch } = useQuery<SkillsExperienceModelList>(
        ['skills_experiences', member_id],
        () =>
            lambdaApi
                .get(`${skillsDBPrefix}/members/${member_id}/experiences`)
                .then(res => res.data),
        { retry: false, enabled: enabled && !!member_id },
    );

    if (data) {
        data.sort((a, b) => {
            return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
        });
    }

    const addExperience = useMutation<
        SkillsExperienceModelList,
        AxiosError,
        SkillsExperienceRequestPayload
    >(payload => {
        return lambdaApi.post(`${skillsDBPrefix}/experiences`, payload).then(res => res.data);
    });

    const updateExperience = useMutation<
        SkillsExperienceModel,
        AxiosError,
        { id: number; payload: SkillsExperienceRequestPayload }
    >(params => {
        const { id, payload } = params;
        return lambdaApi.put(`${skillsDBPrefix}/experiences/${id}`, payload).then(res => res.data);
    });

    const deleteExperience = useMutation<'', AxiosError, number>(id => {
        return lambdaApi.delete(`${skillsDBPrefix}/experiences/${id}`).then(res => res.data);
    });

    return {
        isLoading,
        experiences: data ?? [],
        addExperience,
        updateExperience,
        deleteExperience,
        refetch,
    };
}
