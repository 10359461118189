import * as z from 'zod';

import { mysqlDate } from '.';

export const UpdateMember = z.object({
    id: z.union([z.number(), z.string()]),
    email: z.string().email(),
    firstname: z.string().min(2),
    lastname: z.string().min(2),
    google_id: z.string(),
    is_active: z.boolean(),
    is_admin: z.boolean(),
    employment_start: z.date(),
    role: z.string({
        required_error: 'Role is required',
        invalid_type_error: 'Role must be selected from the list',
    }),
});

export const Member = z.object({
    id: z.number(),
    email: z.string(),
    firstname: z.string(),
    lastname: z.string(),
    google_id: z.string(),
    is_validated: z.boolean(),
    is_active: z.boolean(),
    is_admin: z.boolean(),
    image_url: z.string().nullable().optional(),
    current_membership_count: z.number(),
    weight_sum: z.number(),
    updated_at: mysqlDate,
    employment_start: mysqlDate,
    role: z.string().nullable(),
});

export const MemberList = z.array(Member);

export type MemberType = z.infer<typeof Member>;
export type MemberTypeList = z.infer<typeof MemberList>;
export type UpdateMemberType = z.infer<typeof UpdateMember>;
