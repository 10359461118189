import { useQuery } from 'react-query';

import lambdaApi from '../api/LambdaApi';
import { MemberModel } from '../generated-types/member';

export function useProfile() {
    const { data, isLoading } = useQuery<MemberModel>(['myProfile'], () =>
        lambdaApi.get('/me/profile', {}).then(res => res.data),
    );

    return {
        user: data,
        isAdmin: data?.is_admin ?? false,
        isLoading,
    };
}
