import lambdaApi from 'api/LambdaApi';
import { useQuery } from 'react-query';

import { skillsDBPrefix } from '.';

export function useStatus() {
    const { data, isLoading, isFetched } = useQuery(
        ['skills_db_status'],
        () => lambdaApi.get(`${skillsDBPrefix}/status`, {}).then(res => res.data),
        {
            retry: false,
        },
    );

    const isAvailable = data?.status === 'ok';

    return {
        isAvailable,
        isLoading,
        isFetched,
    };
}
